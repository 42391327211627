<template>
  <NuxtLink v-if="product" :to="product.urlPath" class="wax-intro-product">
    <img
      :src="product.imageUrl"
      :alt="product.imageAlt"
      class="wax-intro-product-image"
      loading="lazy"
    />
    <div class="wax-intro-product-content">
      <div class="wax-intro-product-name">
        {{ product.name }}
      </div>
      <div class="wax-intro-product-note">
        {{ note }}
      </div>
    </div>
  </NuxtLink>
</template>

<script setup>
  import { deserialize } from "@alchemy_cms/json_api"

  const props = defineProps({
    element: {
      type: Object,
      default: () => ({}),
    },
  })

  const { getValue } = useAlchemy()
  const { api } = useApi()
  const { handleError } = useErrorHandling()
  const productId = getValue(props.element, "product")
  const note = getValue(props.element, "note")

  const { data: product } = useLazyAsyncData(
    `${props.element.name}-${props.element.id}`,
    async () => {
      try {
        const data = await api(`/jsonapi/products/${productId}`)
        return deserialize(data)
      } catch (error) {
        handleError(error)
      }
    },
  )
</script>

<style lang="scss" scoped>
  .wax-intro-product {
    display: flex;
    color: inherit;
    text-decoration: none;
    grid-gap: $space-s;
    align-items: flex-start;
    margin-bottom: $base-spacing * 2;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  .wax-intro-product-image {
    box-shadow: $shadow;
    width: $space-m;
    height: $space-m;
    border-radius: $border-radius;
  }

  .wax-intro-product-name {
    font-weight: bold;
  }

  .wax-intro-product-note {
    color: $gray-text;
  }
</style>
